import React, { useState } from "react";
import { LOGIN } from "../../utils/mutations";
import Auth from "../../utils/auth";
import { useMutation } from "@apollo/react-hooks";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Login = (props) => {
  // login form
  const [formState, setFormState] = useState({ email: "", password: "" });
  const [login, { error }] = useMutation(LOGIN);
  const [togglePass, setTogglePass] = useState("password");

  const { setCurrentPage } = props;

  const handleLoginPassToggle = () => {
    if (togglePass === "password") {
      setTogglePass("text");
    } else {
      setTogglePass("password");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const mutationResponse = await login({
        variables: { email: formState.email, password: formState.password },
      });
      const token = mutationResponse.data.login.token;
      Auth.login(token);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  if (Auth.loggedIn() === false) {
    return (
      <div className="">
        <div className="login">
          <h2>Login</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="form-el">
              <label className="form-label" htmlFor="email">
                Email:
              </label>
              <input
                className="form-input"
                name="email"
                type="email"
                id="email"
                onChange={handleChange}
              />
            </div>
            <div className="form-el">
              <label className="form-label" htmlFor="pwd">
                Password:
              </label>
              <div className="pass-input">
                <input
                  className="form-input-password"
                  name="password"
                  type={togglePass}
                  id="pwd"
                  onChange={handleChange}
                />
                {togglePass === "password" ? (
                  <i onClick={handleLoginPassToggle} id="passToggle">
                    <BsEyeSlash />
                  </i>
                ) : (
                  <i onClick={handleLoginPassToggle} id="passToggle">
                    <BsEye />
                  </i>
                )}
              </div>
            </div>
            {error ? (
              <div>
                <p className="error-text">
                  The provided credentials are incorrect
                </p>
              </div>
            ) : null}
            <div>
              <button className="loginBtn" type="submit">
                Submit
              </button>
            </div>
          </form>
          <div>
            <p>Users must be logged in to play.</p>{" "}
            <p>
              Don't have a login? Sign up{" "}
              <a
                className="signupLink"
                href="#signup"
                onClick={() => setCurrentPage("signup")}
              >
                Here
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="logout" onClick={() => Auth.logout()}>
        <h2>You are already logged in</h2>
        <h4>Click here to logout</h4>
      </div>
    );
  }
};

export default Login;
