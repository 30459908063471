import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_SCORE } from "../../../utils/mutations";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_ME } from "../../../utils/queries";

const FiveHouse = (props) => {
  const [mapOpen, setMapOpen] = useState(false);


  const { setCurrentPage } = props;
  const { loading, error: meError, data: meData } = useQuery(QUERY_ME);
  const [addScore, { error }] = useMutation(ADD_SCORE);
  const [saved, setSaved] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [average, setAverage] = useState([]);
  const [hintUsed, setHintUsed] = useState(false);
  const [userAppraisal, setUserAppraisal] = useState();
  const [difference, setDifference] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [hintFailed, setHintFailed] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [houseList, setHouseList] = useState(() => {
    let arr = [];
    while (arr.length < 1988) {
      let r = Math.floor(Math.random() * 1988);
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUseHint = () => {
    try {
    if(average.find((t) => t.zip === data.hdpData.homeInfo.zipcode).price === undefined ){      
      setHintFailed(true);
    } else {
      setShowHint(true);
      setHintUsed(true);
    }
  } catch {
    setHintFailed(true);

  }
    
  };


  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson.listings[houseList[totalCount]]);
      })
      .then(function () {
        setIsLoading(false);
      });
  };
  const nextHouse = () => {
    handleClose();
    setShowHint(false)
    setHintFailed(false);
    setMapOpen(false);
    document.getElementById("appraisal").value = "";
    setUserAppraisal(0);
    setScore(score + getScore());
    setCount(count + 1);
    setTotalCount(totalCount + 1);
  };

  const endGame = () => {
    setScore(score + getScore());
    handleClose();
    setGameOver(true);
    setTotalCount(totalCount + 1);
  };

  const handleAddScore = async () => {
    try {
      const mutationResponse = await addScore({
        variables: {
          game: "classic",
          score: score,
          scoreType: "Score",
          scoreUser: meData.me.username,
          hint: hintUsed
        },
      });
      console.log(mutationResponse);
      setSaved(true);
    } catch (e) {
      console.log(e);
    }
  };

  const nextGame = () => {
    setMapOpen(false);
    setHintUsed(false);
    setHintFailed(false);
    setScore(0);
    setCount(0);
    setShowHint(false)
    setGameOver(false);
    document.getElementById("appraisal").value = "";
    setSaved(false);
    setUserAppraisal(0);
    setHouseList(() => {
      let arr = [];
    while (arr.length < 1988) {
      let r = Math.floor(Math.random() * 1988);
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
    })
    setTotalCount(0);
  };



  const getScore = () => {
    if (percentage <= 1) {
      return 20;
    } else if (percentage <= 3) {
      return 19;
    } else if (percentage <= 5) {
      return 18;
    } else if (percentage <= 7) {
      return 17;
    } else if (percentage <= 9) {
      return 16;
    } else if (percentage <= 11) {
      return 15;
    } else if (percentage <= 13) {
      return 14;
    } else if (percentage <= 15) {
      return 13;
    } else if (percentage <= 17) {
      return 12;
    } else if (percentage <= 19) {
      return 11;
    } else if (percentage <= 21) {
      return 10;
    } else if (percentage <= 23) {
      return 9;
    } else if (percentage <= 25) {
      return 8;
    } else if (percentage <= 27) {
      return 7;
    } else if (percentage <= 29) {
      return 6;
    } else if (percentage <= 31) {
      return 5;
    } else if (percentage <= 33) {
      return 4;
    } else if (percentage <= 35) {
      return 3;
    } else if (percentage <= 37) {
      return 2;
    } else if (percentage <= 40) {
      return 1;
    } else {
      return 0;
    }
  };

  const getAvgPrice = () => {
    fetch("average-price.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setAverage(myJson);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleShow();
    setPercentage(
      Math.floor(
        (100 * diff(userAppraisal, data.hdpData.homeInfo.price)) / data.hdpData.homeInfo.price
      )
    );

    setDifference(diff(userAppraisal, data.hdpData.homeInfo.price));
  };

  const handleChange = (event) => {
    event.preventDefault();

    setUserAppraisal(event.target.value);

    setDifference(diff(userAppraisal, data.hdpData.homeInfo.price));
  };

  const diff = (a, b) => {
    return Math.abs(a - b);
  };

  useEffect(() => {
  getData();
  
  }, [count]);

  useEffect(() => {
  getAvgPrice();
  }, []);
 
  useEffect(() => {
    try{
    if(average.find((t) => t.zip === data.hdpData.homeInfo.zipcode).price === undefined ){      
      setHintFailed(true);
  } else {
      setHintFailed(false);
    }
  } catch {
    setHintFailed(true);
  }
    }, [data]);
  


  return (
    <section className="gameSection">
      {isLoading ? (
        <div className="loaderDiv">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="gameSection">
          {show ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="modalTop">
                  <ul>
                    <li>
                      <span>Actual listing price: </span>
                      <span>${data.hdpData.homeInfo.price}</span>
                    </li>
                    <li>
                      <span>Your Appriasal: </span>
                      <span>${userAppraisal}</span>
                    </li>
                  </ul>
                </div>
                <br />
                <p>You were {percentage}%, Or $
                {difference} away from the listing price.</p>
                <br/>
                {hintFailed === false ? (
                    <p>
                      *The average home price in zipcode{" "}
                      {data.hdpData.homeInfo.zipcode} is $
                      {
                        average.find(
                          (t) => t.zip === data.hdpData.homeInfo.zipcode
                        ).price
                      }
                    </p>
                  ) : 
                    <p>
                      Hint not available for this listing.
                    </p>}
                <div className="modalBtnDiv">
                  <div className="roundScore">
                    <span>+ {getScore()}</span>
                    <span> Points</span>
                  </div>

                  {count === 4 ? (
                    <button className="modalBtn" onClick={endGame}>
                      See Final Score
                    </button>
                  ) : (
                    <button className="modalBtn" onClick={nextHouse}>
                      Next House
                    </button>
                  )}
                </div>
                <p className="fineText">*according to Zillow ( ZHVI )</p>
              </div>
            </div>
          ) : null}

          {gameOver ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="modalTop">
                  
                  <div className="roundScore final">
                    <span>Final Score:</span>
                    <span>{score} Points</span>
                  </div>
                </div>

                <br />

                <div className="modalBtnDiv">
                  <button
                    className="modalBtn"
                    disabled={totalCount > 24}
                    onClick={nextGame}
                  >
                    Play Again?
                  </button>
                  <button
                    className="modalBtn"
                    disabled={saved}
                    onClick={handleAddScore}
                  >
                    Save Score
                  </button>
                  <button
                    className="modalBtn"
                    onClick={() => setCurrentPage("home")}
                  >
                    Main Menu
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="infoColumn">
            <h2>Classic Mode</h2>
            <ul className="specOptions">
              <li>
                <h4>Location:</h4>
                <span id="location" >
                {data.hdpData.homeInfo.city}, {data.hdpData.homeInfo.state}
                  {mapOpen ?
                  <button className="mapBtn" onClick={() => {setMapOpen(false)}}>Return to House</button>
                  :
                  <button className="mapBtn" onClick={() => {setMapOpen(true)}}>View on Maps</button>

                  }
                </span>
              </li>
              <li>
                <h4>Zipcode</h4>
                <span id="zipcode">{data.hdpData.homeInfo.zipcode}</span>
              </li>
              <li>
                <h4>Square Footage:</h4>
                <span id="squareFoot">{data.hdpData.homeInfo.livingArea}</span>
              </li>
              <li>
                <h4>Bed/Bath Count:</h4>
                <span id="bedBathCount">{data.hdpData.homeInfo.bedrooms} Bed {data.hdpData.homeInfo.bathrooms} Bath</span>
              </li>
              <li>
                <h4>Lot Size:</h4>
                <span id="lotSize">{(data.hdpData.homeInfo.lotAreaValue).toFixed(2)} {data.hdpData.homeInfo.lotAreaUnit} </span>
              </li>
              {showHint ?
              <div className="hintEl">
                <p className="hintText">Average home price for this zipcode is: ${average.find(t=>t.zip === data.hdpData.homeInfo.zipcode).price}</p>
              </div> 
              :
              <div className="hintEl">
                {hintFailed === false ? 
                      <button className="mapBtn" onClick={handleUseHint}>
                        Use Hint
                      </button>
                      :
                      <p>
                        Hint not available for this listing.
                      </p>
                      }
              </div> 
              }             
            </ul>
            <div className="guessDiv">
              <form className="guessForm" onSubmit={handleSubmit}>
                <label htmlFor="appraisal">Your Appraisal</label>
                <p className="fineText">Enter number without , or $</p>
                <input
                  placeholder="Enter Appraisal"
                  id="appraisal"
                  name="appraisal"
                  type="number"
                  onChange={handleChange}
                ></input>
                <input
                  className="submitBtn"
                  type="submit"
                  value="Submit Appraisal"
                  disabled={!userAppraisal}
                ></input>
              </form>
            </div>
            <div className="currentStatDiv">
              <span>Current Score: {score}</span>
              <span>House {count + 1}/5</span>
            </div>
          </div>
          <div className="listingImages">
            {mapOpen ?
              <iframe
                className="houseListingImage houseMap"
                  width="100%"
                  height="100%"                 
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDDriTZDiM_9BYt07b7VQzKKbTVQEIBFOs&zoom=10&q=${data.hdpData.homeInfo.zipcode}`}
                ></iframe>
                :
            <img
              className="houseListingImage"
              src={data.imgSrc}
              alt="house listing"
            ></img>
            }
          </div>
        </div>
      )}
    </section>
  );
};

export default FiveHouse;
