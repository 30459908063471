import {gql} from "@apollo/client";



export const QUERY_ME = gql`
  {
    me {
      _id
      username
      firstName
      lastName
      email
      dateJoined
      scores {
        _id
        game
        score
        scoreType
        scoreDate
        hint
      }
    }
  }
`;


export const QUERY_USERS = gql`
  query user($_id: ID!) {
    user(_id: $_id) {
      _id
      firstName
      lastName
      email
      dateJoined
      scores {
        _id
        game
        score
        scoreType
      }
    }
  }
`;

export const QUERY_ALL_USERS = gql`
{
  users {
    _id
    firstName
    lastName
    email
    dateJoined
    scores {
      _id
      game
      score
      scoreType
    }
  }
}
`;

export const QUERY_ALL_SCORES = gql`
{
  allScores {
    _id
    game
    score
    scoreType
    scoreDate
    scoreUser
    hint
  }
}
`;
