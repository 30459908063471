import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_SCORE } from "../../../utils/mutations";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_ME } from "../../../utils/queries";

const Streak = (props) => {
  const [mapOpen, setMapOpen] = useState(false);

  const { setCurrentPage } = props;
  const { loading, error: meError, data: meData } = useQuery(QUERY_ME);
  const [addScore, { error }] = useMutation(ADD_SCORE);
  const [saved, setSaved] = useState(false);
  const [average, setAverage] = useState([]);

  const [hintUsed, setHintUsed] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [accuracy, setAccuracy] = useState(50);
  const [userAppraisal, setUserAppraisal] = useState();
  const [difference, setDifference] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [hintFailed, setHintFailed] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [houseList, setHouseList] = useState(() => {
    let arr = [];
    while (arr.length < 1988) {
      let r = Math.floor(Math.random() * 1988);
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUseHint = () => {
    try {
    if(average.find((t) => t.zip === data.hdpData.homeInfo.zipcode).price === undefined ){      
      setHintFailed(true);
    } else {
      setShowHint(true);
      setHintUsed(true);
    }
  } catch {
    setHintFailed(true);

  }
    
  };
  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson.listings[houseList[totalCount]]);
      })
      .then(function () {
        setIsLoading(false);
      });
  };
  const nextHouse = () => {
    handleClose();
    getAccuracy();
    setShowHint(false);
    setHintFailed(false);
    setMapOpen(false);
    document.getElementById("appraisal").value = "";
    setUserAppraisal(0);

    setTotalCount(totalCount + 1);
  };

  const handleAddStreak = async () => {
    try {
      const mutationResponse = await addScore({
        variables: {
          game: 'streak',
          score: totalCount,
          scoreType: 'Streak',
          scoreUser: meData.me.username,
          hint: hintUsed,
        },
      });
      setSaved(true);
    } catch (e) {
      console.log(e);
    }
  };

  const nextGame = () => {
    setMapOpen(false);
    setHintUsed(false);
    setHintFailed(false);
    setGameOver(false);
    document.getElementById("appraisal").value = "";
    setShowHint(false);
    setSaved(false);
    setUserAppraisal(0);
    setHouseList(() => {
      let arr = [];
      while (arr.length < 1988) {
        let r = Math.floor(Math.random() * 1988);
        if (arr.indexOf(r) === -1) arr.push(r);
      }
      return arr;
    });
    setTotalCount(0);
  };

  const getAccuracy = () => {
    if (totalCount < 4) {
      setAccuracy(50);
    } else if (totalCount < 9) {
      setAccuracy(40);
    } else if (totalCount < 14) {
      setAccuracy(30);
    } else if (totalCount < 19) {
      setAccuracy(20);
    } else if (totalCount < 24) {
      setAccuracy(10);
    }
  };

  const passOrFail = (p) => {
    if (totalCount < 5) {
      if (p > 50) {
        setGameOver(true);
        handleClose();
        return;
      }
    } else if (totalCount < 10) {
      if (p > 40) {
        setGameOver(true);
        handleClose();
        return;
      }
    } else if (totalCount < 15) {
      if (p > 30) {
        setGameOver(true);
        handleClose();
        return;
      }
    } else if (totalCount < 20) {
      if (p > 20) {
        setGameOver(true);
        handleClose();
        return;
      }
    } else if (totalCount < 25) {
      if (p > 10) {
        setGameOver(true);
        handleClose();
        return;
      }
    }
  };

  const getAvgPrice = () => {
    fetch("average-price.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setAverage(myJson);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleShow();
    setPercentage(
      Math.floor(
        (100 * diff(userAppraisal, data.hdpData.homeInfo.price)) /
          data.hdpData.homeInfo.price
      )
    );
    passOrFail(
      Math.floor(
        (100 * diff(userAppraisal, data.hdpData.homeInfo.price)) /
          data.hdpData.homeInfo.price
      )
    );

    setDifference(diff(userAppraisal, data.hdpData.homeInfo.price));
  };

  const handleChange = (event) => {
    event.preventDefault();

    setUserAppraisal(event.target.value);

    setDifference(diff(userAppraisal, data.hdpData.homeInfo.price));
  };

  const diff = (a, b) => {
    return Math.abs(a - b);
  };

  useEffect(() => {
    getData();
  }, [totalCount]);

  useEffect(() => {
    getAvgPrice();
  }, []);

  useEffect(() => {
    try{
    if(average.find((t) => t.zip === data.hdpData.homeInfo.zipcode).price === undefined ){      
      setHintFailed(true);
  } else {
      setHintFailed(false);
    }
  } catch {
    setHintFailed(true);
  }
    }, [data]);

  return (
    <section className="gameSection">
      {isLoading ? (
        <div className="loaderDiv">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="gameSection">
          {show ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="modalTop">
                  <ul>
                    <li>
                      <span>Actual listing price: </span>
                      <span>${data.hdpData.homeInfo.price}</span>
                    </li>
                    <li>
                      <span>Your Appriasal: </span>
                      <span>${userAppraisal}</span>
                    </li>
                  </ul>
                </div>
                <br />
                <p>
                  You were {percentage}%, Or ${difference} away from the listing
                  price.
                </p>
                <br />
                {hintFailed === false ? (
                    <p>
                      *The average home price in zipcode{" "}
                      {data.hdpData.homeInfo.zipcode} is $
                      {
                        average.find(
                          (t) => t.zip === data.hdpData.homeInfo.zipcode
                        ).price
                      }
                    </p>
                  ) : 
                    <p>
                      Hint not available for this listing.
                    </p>}
                <div className="modalBtnDiv">
                  <div className="roundScore">
                    <span>Current Streak:</span>
                    <span>{totalCount + 1}</span>
                  </div>

                  <button className="modalBtn" onClick={nextHouse}>
                    Next House
                  </button>
                </div>
                <p className="fineText">*according to Zillow ( ZHVI )</p>
              </div>
            </div>
          ) : null}

          {gameOver ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="modalTop">
                  <h3>GAME OVER</h3>
                  <ul>
                    <li>
                      <span>Actual listing price: </span>
                      <span>${data.hdpData.homeInfo.price}</span>
                    </li>
                    <li>
                      <span>Your Appriasal: </span>
                      <span>${userAppraisal}</span>
                    </li>
                  </ul>
                  <br />
                  <p>
                    You were {percentage}%, Or ${difference} away from the
                    listing price.
                  </p>
                  <br />
                  {hintFailed === false ? (
                    <p>
                      *The average home price in zipcode{" "}
                      {data.hdpData.homeInfo.zipcode} is $
                      {
                        average.find(
                          (t) => t.zip === data.hdpData.homeInfo.zipcode
                        ).price
                      }
                    </p>
                  ) : 
                    <p>
                      Hint not available for this listing.
                    </p>}
                  <div className="roundScore final">
                    <span>Final Streak:</span>
                    <span>{totalCount}</span>
                  </div>
                </div>

                <div className="modalBtnDiv">
                  <button
                    className="modalBtn"
                    disabled={totalCount > 24}
                    onClick={nextGame}
                  >
                    Play Again?
                  </button>
                  <button
                    className="modalBtn"
                    disabled={saved}
                    onClick={handleAddStreak}
                  >
                    Save Score
                  </button>
                  <button
                    className="modalBtn"
                    onClick={() => setCurrentPage("home")}
                  >
                    Main Menu
                  </button>
                </div>
                <p className="fineText">*according to Zillow ( ZHVI )</p>
              </div>
            </div>
          ) : null}
          <div className="infoColumn">
            <h2>Streak Mode</h2>
            <ul className="specOptions">
              <li>
                <h4>Location:</h4>
                <span id="location">
                  {data.hdpData.homeInfo.city}, {data.hdpData.homeInfo.state}
                  {mapOpen ? (
                    <button
                      className="mapBtn"
                      onClick={() => {
                        setMapOpen(false);
                      }}
                    >
                      Return to House
                    </button>
                  ) : (
                    <button
                      className="mapBtn"
                      onClick={() => {
                        setMapOpen(true);
                      }}
                    >
                      View on Maps
                    </button>
                  )}
                </span>
              </li>
              <li>
                <h4>Zipcode</h4>
                <span id="zipcode">{data.hdpData.homeInfo.zipcode}</span>
              </li>
              <li>
                <h4>Square Footage:</h4>
                <span id="squareFoot">{data.hdpData.homeInfo.livingArea}</span>
              </li>
              <li>
                <h4>Bed/Bath Count:</h4>
                <span id="bedBathCount">
                  {data.hdpData.homeInfo.bedrooms} Bed{" "}
                  {data.hdpData.homeInfo.bathrooms} Bath
                </span>
              </li>
              <li>
                <h4>Lot Size:</h4>
                <span id="lotSize">
                  {data.hdpData.homeInfo.lotAreaValue.toFixed(2)}{" "}
                  {data.hdpData.homeInfo.lotAreaUnit}{" "}
                </span>
              </li>

              {showHint ?
              <div className="hintEl">
                <p className="hintText">Average home price for this zipcode is: ${average.find(t=>t.zip === data.hdpData.homeInfo.zipcode).price}</p>
              </div> 
              :
              <div className="hintEl">
                {hintFailed === false ? 
                      <button className="mapBtn" onClick={handleUseHint}>
                        Use Hint
                      </button>
                      :
                      <p>
                        Hint not available for this listing.
                      </p>
                      }
              </div> 
              }  
            </ul>
            <div className="guessDiv">
              <form className="guessForm" onSubmit={handleSubmit}>
                <label htmlFor="appraisal">Your Appraisal</label>
                <p className="fineText">Enter number without , or $</p>
                <input
                  placeholder="Enter Appraisal"
                  id="appraisal"
                  name="appraisal"
                  type="number"
                  onChange={handleChange}
                ></input>
                <input
                  className="submitBtn"
                  type="submit"
                  value="Submit Appraisal"
                  disabled={!userAppraisal}
                ></input>
              </form>
            </div>
            <div className="currentStatDiv">
              <span>Current Streak: {totalCount}</span>
              <span>Accuracy Required: {accuracy}%</span>
            </div>
          </div>
          <div className="listingImages">
            {mapOpen ? (
              <iframe
                className="houseListingImage houseMap"
                width="100%"
                height="100%"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDDriTZDiM_9BYt07b7VQzKKbTVQEIBFOs&zoom=10&q=${data.hdpData.homeInfo.zipcode}`}
              ></iframe>
            ) : (
              <img
                className="houseListingImage"
                src={data.imgSrc}
                alt="house listing"
              ></img>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Streak;
