import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { QUERY_ME, QUERY_ALL_SCORES } from "../../utils/queries";
import { UPDATE_USER } from "../../utils/mutations";

const Account = (props) => {
  const { loading, error, data } = useQuery(QUERY_ME);
  const { data: scoreData} = useQuery(QUERY_ALL_SCORES);
 

  const [leaderboard, setLeaderboard] = useState("");

  const [updateUser, { error: errorUpdate }] = useMutation(UPDATE_USER);
  const [changeInfoState, setChangeInfoState] = useState({
    username: "",
    email: "",
  });

  const [ changePassword, setChangePassword] = useState({
    pass1: "",
    pass2: ""
  })

  const [passMatch, setPassMatch] = useState(false);
  const [personalDiv, setPersonalDiv] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);
  const [passDiv, setPassDiv] = useState(false);
  const [meScoresDiv, setMeScoresDiv] = useState(false);
  let dateOptions = {
    hour: "numeric",
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    minute: "2-digit",
  };

  const togglePersonalDiv = () => {
    setPersonalDiv(true);
    setMeScoresDiv(false);
    setChangeInfoState({ username: data.me.username, email: data.me.email });
    setPassDiv(false);
    setChangeInfo(false);
  };

  const toggleScoreDiv = () => {
    setPersonalDiv(false);
    setMeScoresDiv(true);
    setPassDiv(false);
    setChangeInfo(false);
  };

  const togglePassDiv = () => {
    setPersonalDiv(false);
    setMeScoresDiv(false);
    setChangeInfo(false);
    setPassDiv(true);
  };

  let me = { username: "", dateJoined: "", email: "" };

  if (data) {
    me.dateJoined = data.me.dateJoined;
    me.email = data.me.email;
    me.username = data.me.username;
  } else if (error) {
    console.log(error);
  }

  if (scoreData) {
    console.log(scoreData);
  }

  const orderTopScores = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'classic');
    arr.sort(function (a, b) {
      return b.score - a.score;
    });
    
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const orderTopStreaks = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'streak');
    arr.sort(function (a, b) {
      return b.score - a.score;
    });
    
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const orderTopMost = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'most');
    arr.sort(function (a, b) {
      return b.score - a.score;
    });
    
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const orderRecentScores = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'classic').reverse();
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const orderRecentStreaks = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'streak').reverse();
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const orderRecentMost = () => {
    let arr = [...scoreData.allScores].filter(g => g.game === 'most').reverse();
    return arr.filter(s=>s.scoreUser === me.username).slice(0, 10);
  };

  const handleInfoSubmit = async (event) => {
    event.preventDefault();
    try {
      const mutationResponse = await updateUser({
        variables: {
          username: changeInfoState.username,
          email: changeInfoState.email,
        },
      });
      console.log(mutationResponse);
      togglePersonalDiv();
    } catch (e) {
      console.log(e);
    }
  };

  const handlePassSubmit = async (event) => {
    event.preventDefault();
    if (changePassword.pass1 === changePassword.pass2) {
    try {
      const mutationResponse = await updateUser({
        variables: {
          password: changePassword.pass1,
        },
      });
      console.log(mutationResponse);
      console.log(changePassword.pass1);
      togglePersonalDiv();
    } catch (e) {
      console.log(e);
    }
  } else {
    setPassMatch(true)
  }
  }

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setChangeInfoState({
      ...changeInfoState,
      [name]: value,
    });
  };

  const handlePassChange = (event) => {
    const { name, value } = event.target;
    setChangePassword({
      ...changePassword,
      [name]: value,
    });
  };


  return (
    <div className="accountDiv">
      <div className="accountBtnDiv">
        <button disabled={personalDiv === true} onClick={togglePersonalDiv}>
          Profile
        </button>
        <button disabled={meScoresDiv === true} onClick={toggleScoreDiv}>
          Your Scores
        </button>
      </div>

      {personalDiv && (!changeInfo && !passDiv) ? (
        <div className="accountDiv">
          <h3>Account Info</h3>
          <ul className="profileDiv">
            <li>
              <span>Username: </span>
              <span>{me.username}</span>
            </li>
            <li>
              <span>Email: </span>
              <span>{me.email}</span>
            </li>
            <li>
              <span>Date Joined: </span>
              <span>
                {new Date(parseInt(me.dateJoined)).toLocaleDateString(
                  "en-US",
                  dateOptions
                )}
              </span>
            </li>
          </ul>
          <div className="changeInfoDiv">
          <button className="infoBtn" onClick={() => setChangeInfo(true)}>
            Change User Info
          </button>
          <button className="infoBtn" onClick={() => setPassDiv(true)}>
            Change Password
          </button>
          </div>
        </div>
      ) : null}
      {personalDiv && changeInfo ? (
        <form className="accountDiv infoChangeDiv" onSubmit={handleInfoSubmit}>
          <div className="form-el ">
            <label className="form-label" htmlFor="username">
              Username:
            </label>
            <input
              className="form-input"
              name="username"
              type="username"
              id="username"
              value={changeInfoState.username ? changeInfoState.username : ""}
              onChange={handleInfoChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              className="form-input"
              name="email"
              type="email"
              id="email"
              value={changeInfoState.email}
              onChange={handleInfoChange}
            />
          </div>
          {errorUpdate ? (
            <div className="error-div">
              <p className="error-text">
                Something went wrong- New Username or Email is invalid or already in
                use, choose another one.
              </p>
            </div>
          ) : null}
          <div className="form-el infoBtnDiv">
            <button className="infoBtn" type="submit">
              Save Changes
            </button>
            <button
              className="infoBtn"
              type="button"
              onClick={togglePersonalDiv}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : null}
      {personalDiv && passDiv ? (
        <form className="accountDiv infoChangeDiv" onSubmit={handlePassSubmit}>
          <div className="form-el">
            <label className="form-label" htmlFor="password">
              New Password:
            </label>
            <input
              className="form-input"
              name="pass1"
              type="password"
              id="pass1"
              value={changePassword.pass1}
              onChange={handlePassChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="password">
              Do It Again:
            </label>
            <input
              className="form-input"
              name="pass2"
              type="password"
              id="pass2"
              value={changePassword.pass2}
              onChange={handlePassChange}
            />
          </div>
          {errorUpdate ? (
            <div className="error-div">
              <p className="error-text">
                Invalid Password
              </p>
            </div>
          ) : null}
          {passMatch ? (
            <div className="error-div">
              <p className="error-text">
                Passwords don't match
              </p>
            </div>
          ) : null}
          <div className="form-el infoBtnDiv">
            <button className="infoBtn" type="submit">
              Save Changes
            </button>
            <button
              className="infoBtn"
              type="button"
              onClick={togglePassDiv}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : null}

      {meScoresDiv ? (
        <div className="scoreDiv">
          <div className="leaderBtnDiv">
        <button disabled={leaderboard === '5house'} onClick={() => setLeaderboard('5house')}>
        Classic Leaderboard
        </button>
        <button disabled={leaderboard === 'streak'} onClick={() => setLeaderboard('streak')}>
          Streak Leaderboard
        </button>
        <button disabled={leaderboard === 'most'} onClick={() => setLeaderboard('most')}>
          Most Leaderboard
        </button>
        
      </div>
      {loading === false ? (
        <div>
          {leaderboard === "5house" ? (
            <div className="scoreBoard">
              <p>*Refresh the page to view most recent scores*</p>
              <ul className="scoreList">
                <h3>Top Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Score</span>
                    <span>Date
                    </span>
                  </li>
                {orderTopScores().map((score) => (
                  <li className="scoreListItem topScore" key={score._id}>
                    <span>{score.score} {score.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(score.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
              <ul className="scoreList">
                <h3>Recent Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Score</span>
                    <span>Date
                    </span>
                  </li>
                {orderRecentScores().map((score) => (
                  <li className="scoreListItem recentScore" key={score._id}>
                    <span>{score.score} {score.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(score.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {leaderboard === "streak" ? (
            <div className="scoreBoard">
              <p>*Refresh the page to view most recent scores*</p>
              <ul className="scoreList">
                <h3>Top Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Streak of:</span>
                    <span>Date
                    </span>
                  </li>
                {orderTopStreaks().map((streak) => (
                  <li className="scoreListItem topScore" key={streak._id}>
                    <span>{streak.score} {streak.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(streak.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
              <ul className="scoreList">
                <h3>Recent Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Streak of:</span>
                    <span>Date
                    </span>
                  </li>
                {orderRecentStreaks().map((streak) => (
                  <li className="scoreListItem recentScore" key={streak._id}>
                    <span>{streak.score} {streak.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(streak.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {leaderboard === "most" ? (
            <div className="scoreBoard">
              <p>*Refresh the page to view most recent scores*</p>
              <ul className="scoreList">
                <h3>Top Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Streak of:</span>
                    <span>Date
                    </span>
                  </li>
                {orderTopMost().map((most) => (
                  <li className="scoreListItem topScore" key={most._id}>
                    <span>{most.score} {most.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(most.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
              <ul className="scoreList">
                <h3>Recent Scores:</h3>
                <li className="scoreListItem scoreListHeader">
                    <span>Streak of:</span>
                    <span>Date
                    </span>
                  </li>
                {orderRecentMost().map((most) => (
                  <li className="scoreListItem recentScore" key={most._id}>
                    <span>{most.score} {most.hint === true ? <span>*</span> : null}</span>
                    <span>
                      {new Date(parseInt(most.scoreDate)).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          -scores with a * used a hint-
          </div>
      ): null}
        </div>
      ) : null}
    </div>
  );
};

export default Account;
