import React, { useState } from "react";
// import ApolloClient, { InMemoryCache } from "apollo-boost";
// import { ApolloProvider } from "@apollo/react-hooks";



// import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// import {onError} from '@apollo/client/link/error';


import Nav from "./components/Nav";
import Home from "./components/Home";
import Game from "./components/Game";
import Account from "./components/Account";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Leader from "./components/Leader";
// const client = new ApolloClient({
//   request: (operation) => {
//     const token = localStorage.getItem("id_token");
//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : "",
//       },
//     });
//   },
//   cache: new InMemoryCache(),
//   uri: "/graphql",
// });

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


function App() {
  const [currentpage, setCurrentPage] = useState("home");

  function renderPage() {
    switch (currentpage) {
      case "home":
        return <Home setCurrentPage={setCurrentPage} />;
      case "game":
        return <Game setCurrentPage={setCurrentPage} />;
      case "leader":
        return <Leader setCurrentPage={setCurrentPage} />;
      case "account":
        return <Account setCurrentPage={setCurrentPage} />;
      case "login":
        return <Login currentpage={currentpage} setCurrentPage={setCurrentPage}/>;
      case "signup":
        return <Signup setCurrentPage={setCurrentPage} />;
            default:
        return <Home setCurrentPage={setCurrentPage} />;
    }
  }

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <main>
          <Nav currentpage={currentpage} setCurrentPage={setCurrentPage}></Nav>
          <div className="main_content">{renderPage()}</div>
        </main>
      </div>
    </ApolloProvider>
  );
}

export default App;
