import React, { useState} from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  QUERY_ALL_SCORES
  
} from "../../utils/queries";


const Account = (props) => {
    const [leaderboard, setLeaderboard] = useState('');
    const [filter, setFilter] = useState('nohints');


  const {
    loading: loadingAll,
    error: errorAll,
    data: dataAll,
  } = useQuery(QUERY_ALL_SCORES);
  
  if (errorAll) {
    console.log(errorAll);
  }

  const orderLeaderTopScores = () => {
    let arr = [...dataAll.allScores].filter(g => g.game === 'classic');
    
    arr.sort(function (a, b) {
      return b.score - a.score;
    });

    if (filter === 'nohints') {
    return arr.filter(h => h.hint === false).slice(0, 25);
    } else if (filter === 'hints') {
    return arr.filter(h => h.hint === true).slice(0, 25);
    } else if ( filter === 'all') {
      return arr.slice(0, 25);
    }

  };

  const orderLeaderTopStreaks = () => {
    let arr = [...dataAll.allScores].filter(g => g.game === 'streak');
    
    arr.sort(function (a, b) {
      return b.score - a.score;
    });

    if (filter === 'nohints') {
    return arr.filter(h => h.hint === false).slice(0, 25);
    } else if (filter === 'hints') {
    return arr.filter(h => h.hint === true).slice(0, 25);
    } else if ( filter === 'all') {
      return arr.slice(0, 25);
    }

  };

  const orderLeaderTopMosts = () => {
    let arr = [...dataAll.allScores].filter(g => g.game === 'most');
    
    arr.sort(function (a, b) {
      return b.score - a.score;
    });

    if (filter === 'nohints') {
    return arr.filter(h => h.hint === false).slice(0, 25);
    } else if (filter === 'hints') {
    return arr.filter(h => h.hint === true).slice(0, 25);
    } else if ( filter === 'all') {
      return arr.slice(0, 25);
    }

  };



  return (
      
    <div className="leaderboardDiv">   
        <h2>Game Modes</h2>

    <div className="leaderBtnDiv">
        <button disabled={leaderboard === '5house'} onClick={() => setLeaderboard('5house')}>
          Classic Leaderboard
        </button>
        <button disabled={leaderboard === 'streak'} onClick={() => setLeaderboard('streak')}>
          Streak Leaderboard
        </button>
        <button disabled={leaderboard === 'most'} onClick={() => setLeaderboard('most')}>
          Most Leaderboard
        </button>
        
      </div>    
      <div className="filter">
        <h3>Filter Leaderboard</h3>
        <button disabled={filter === 'all'} onClick={() => setFilter('all')}>All Scores</button>
        <button disabled={filter === 'hints'} onClick={() => setFilter('hints')}>With Hints</button>
        <button disabled={filter === 'nohints'} onClick={() => setFilter('nohints')}>Without Hints</button>

      </div>
    {leaderboard === '5house' ? (    
        <div className="accountDiv">
          <ul className="scoreList leaderboard">
            <h3>Leaderboard</h3>
            <li className="scoreListItem scoreListHeader" >
                <span>Name</span>
                <span>Score</span>
                <span>Date</span>
              </li>
            {orderLeaderTopScores().map((score) => (
              <li className="scoreListItem topScore" key={score._id}>
                <span>{score.scoreUser}</span>
                <span>{score.score} {score.hint === true ? <span>*</span> : null}</span>
                <span>
                  {new Date(parseInt(score.scoreDate)).toLocaleDateString('en-US')}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {leaderboard === 'streak' ? (    
        <div className="accountDiv">
          <ul className="scoreList leaderboard">
            <h3>Leaderboard</h3>
            <li className="scoreListItem scoreListHeader" >
                <span>Name</span>
                <span>Streak of:</span>
                <span>Date</span>
              </li>
            {orderLeaderTopStreaks().map((streak) => (
              <li className="scoreListItem topScore" key={streak._id}>
                <span>{streak.scoreUser}</span>
                <span>{streak.score} {streak.hint === true ? <span>*</span> : null}</span>
                <span>
                  {new Date(parseInt(streak.scoreDate)).toLocaleDateString('en-US')}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {leaderboard === 'most' ? (    
        <div className="accountDiv">
          <ul className="scoreList leaderboard">
            <h3>Leaderboard</h3>
            <li className="scoreListItem scoreListHeader" >
                <span>Name</span>
                <span>Streak of:</span>
                <span>Date</span>
              </li>
            {orderLeaderTopMosts().map((most) => (
              <li className="scoreListItem topScore" key={most._id}>
                <span>{most.scoreUser}</span>
                <span>{most.score} {most.hint === true ? <span>*</span> : null}</span>
                <span>
                  {new Date(parseInt(most.scoreDate)).toLocaleDateString('en-US')}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Account;
