import React, { useState } from "react";
import FiveHouse from "./5House";
import Streak from "./Streak";
import Most from "./Most";

const Game = (props) => {
  const [gameMode, setGameMode] = useState("");
  const { setCurrentPage } = props;

  return (
    <section className="gameModeSection">
      {gameMode === "" ? (
        <div className="gameModeBtnDiv">
          <button onClick={() => setGameMode("fiveHouse")}>
                <h3>Classic Mode</h3>
                <br />
                <p className="wallOfText">
                  Using the info provided, your job is to make an appraisal for each
                  of 5 houses. Earn points up to 20 per house based on how close to
                  the actual listing price you are.
                </p>

               
             </button>
          <button onClick={() => setGameMode("streak")}>
          <h3>Streak Mode</h3>
                <br />
                <p className="wallOfText">
                Using the info provided, your job is to make an appraisal for the houses shown.
                If you are close enough, move on to the next house and see how many in a row you
                can get right.

                </p>

          </button>
          <button onClick={() => setGameMode("moreLess")}>
                <h3>Expensive-ist Mode</h3>
                <br />
                <p className="wallOfText">
                  You will be presented with 3 houses and your job is to determine which
                  one costs the most. If correct, you'll see 3 new houses. See how many in a row
                  you can get right!
                </p>

               
             </button>
        </div>
      ) : (
        <div className="">
          <button className="gameMenuReturnBtn" onClick={() => setGameMode("")}>Return to Game Menu</button>
        </div>
      )}
      {gameMode === "fiveHouse" ? (
        <FiveHouse setCurrentPage={setCurrentPage} setGameMode={setGameMode} />
      ) : null}
      {gameMode === "streak" ? (
        <Streak setCurrentPage={setCurrentPage} setGameMode={setGameMode} />
      ) : null}
      {gameMode === "moreLess" ? (
        <Most setCurrentPage={setCurrentPage} setGameMode={setGameMode} />
      ) : null}
    </section>
  );
};

export default Game;
