import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($username: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!) {
    addUser(username: $username, firstName: $firstName, lastName: $lastName, email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser( $username: String, $firstName: String, $lastName: String, $email: String, $password: String) {
    updateUser(username: $username, firstName: $firstName, lastName: $lastName, email: $email, password: $password) {
      _id
      username
      firstName
      lastName
      email
    }
  }
`;
export const ADD_SCORE = gql`
mutation addScore($game: String, $score: Float, $scoreType: String, $scoreUser: String, $hint: Boolean) {
  addScore(game: $game, score: $score, scoreType: $scoreType, scoreUser: $scoreUser, hint: $hint) {
  _id
  game
  score
  scoreType
  scoreDate
  scoreUser
  hint
  }
}
`;