import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_SCORE } from "../../../utils/mutations";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_ME } from "../../../utils/queries";

const Most = (props) => {
  const [mapOpen, setMapOpen] = useState(false);

  const { setCurrentPage } = props;
  const { loading, error: meError, data: meData } = useQuery(QUERY_ME);
  const [addScore, { error }] = useMutation(ADD_SCORE);
  const [saved, setSaved] = useState(false);
  const [average, setAverage] = useState([]);

  const [hintUsed, setHintUsed] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [hintFailed, setHintFailed] = useState(false);
  const [houseCount, setHouseCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [houseList, setHouseList] = useState(() => {
    let arr = [];
    while (arr.length < 1988) {
      let r = Math.floor(Math.random() * 1988);
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUseHint = () => {
    try {
    if(average.find(t=>t.zip === data[houseList[houseCount]].hdpData.homeInfo.zipcode).price === undefined || average.find(t=>t.zip === data[houseList[houseCount+1]].hdpData.homeInfo.zipcode).price === undefined || average.find(t=>t.zip === data[houseList[houseCount+2]].hdpData.homeInfo.zipcode).price === undefined){      
      setHintFailed(true);
    } else {
      setShowHint(true);
      setHintUsed(true);
    }
  } catch {
    setHintFailed(true);

  }
    
  };

  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson.listings);
      })
      .then(function () {
        setIsLoading(false);
      });
  };
  const nextHouse = () => {
    handleClose();
    setShowHint(false);
    setMapOpen(false);
    setHintFailed(false);
    setTotalCount(totalCount + 1);
    setHouseCount(houseCount + 3);
  };

  const handleAddMost = async () => {
    try {
      const mutationResponse = await addScore({
        variables: {
          game: 'most',
          score: totalCount,
          scoreType: 'Streak',
          scoreUser: meData.me.username,
          hint: hintUsed,
        },
      });
      setSaved(true);
    } catch (e) {
      console.log(e);
    }
  };

  const nextGame = () => {
    setHintFailed(false);
    setMapOpen(false);
    setHintUsed(false);
    setGameOver(false);
    setShowHint(false);
    setSaved(false);
    setHouseList(() => {
      let arr = [];
      while (arr.length < 1988) {
        let r = Math.floor(Math.random() * 1988);
        if (arr.indexOf(r) === -1) arr.push(r);
      }
      return arr;
    });
    setTotalCount(0);
    setHouseCount(0);
  };

  const makeHouseArr = () => {
    if (data) {
      let arr = [];
      for(let i =0; i < 3;i++) {
      arr.push(data[houseList[houseCount+i]]);
      }
      return arr;
    }
  };

  const checkAnswer = (guess) => { 
    let g;
    if (guess === data[houseList[houseCount]].hdpData.homeInfo.price) {


      g = guess;
      if(g >= data[houseList[houseCount + 1]].hdpData.homeInfo.price && g >= data[houseList[houseCount + 2]].hdpData.homeInfo.price) {
        handleCorrectAnswer();
      } else {
        handleWrongAnswer();
      }
    } else if (guess === data[houseList[houseCount+1]].hdpData.homeInfo.price) {


      g = data[houseList[houseCount + 1]].hdpData.homeInfo.price;
      if(g >= data[houseList[houseCount]].hdpData.homeInfo.price && g >= data[houseList[houseCount + 2]].hdpData.homeInfo.price) {
        handleCorrectAnswer();
      } else {
        handleWrongAnswer();
      }
    } else {


      g = data[houseList[houseCount + 2]].hdpData.homeInfo.price;
      if(g >= data[houseList[houseCount]].hdpData.homeInfo.price && g >= data[houseList[houseCount + 1]].hdpData.homeInfo.price) {
        handleCorrectAnswer();
      } else {
        handleWrongAnswer();
      }
    }
  };

  const handleCorrectAnswer = () => {
    handleShow();
  }

  const handleWrongAnswer = () => {
    setGameOver(true)
  }

  const getAvgPrice = () => {
    fetch("average-price.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setAverage(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, [totalCount]);

  useEffect(() => {
    getAvgPrice();
  }, []);

  useEffect(() => {
    try {
      if(average.find(t=>t.zip === data[houseList[houseCount]].hdpData.homeInfo.zipcode).price === undefined || average.find(t=>t.zip === data[houseList[houseCount+1]].hdpData.homeInfo.zipcode).price === undefined || average.find(t=>t.zip === data[houseList[houseCount+2]].hdpData.homeInfo.zipcode).price === undefined){      
        setHintFailed(true);
      } else {
        setHintFailed(false);
      }
    } catch {
      setHintFailed(true);
  
    }
    }, [data]);

  return (
    <section className="gameSection">
      {isLoading ? (
        <div className="loaderDiv">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="gameSection">
          {show ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="moreModalTop">
                  <ul>
                    <li>
                      House 1 has a listing price of: ${data[houseList[houseCount]].hdpData.homeInfo.price}
                      <br/>
                      <br/>
                      {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount]].hdpData.homeInfo.zipcode).price}
                      </p>
                      :  
                      <p>
                        Hint not available for this listing.
                      </p>}
                    </li>
                    <li>
                    House 2 has a listing price of: ${data[houseList[houseCount+1]].hdpData.homeInfo.price}
                      <br/>
                    <br/>
                    {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount+1]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount+1]].hdpData.homeInfo.zipcode).price}
                      </p>
                      : 
                      <p>
                        Hint not available for this listing.
                      </p>
                      }
                    </li>
                    <li>
                    House 3 has a listing price of: ${data[houseList[houseCount+2]].hdpData.homeInfo.price}
                      <br/>
                    <br/>
                    {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount+2]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount+2]].hdpData.homeInfo.zipcode).price}
                      </p>
                      :  
                      <p>
                        Hint not available for this listing.
                      </p>}
                    </li>
                  </ul>
                </div>
                <br />

                <div className="modalBtnDiv">
                  <div className="roundScore">
                    <span>Current Streak:</span>
                    <span>{totalCount + 1}</span>
                  </div>

                  <button className="modalBtn" onClick={nextHouse}>
                    Next House
                  </button>
                </div>
                <p className="fineText">*according to Zillow ( ZHVI )</p>
              </div>
            </div>
          ) : null}

          {gameOver ? (
            <div id="open-modal" className="modal-window">
              <div>
                <div className="moreModalTop">
                  <h3>GAME OVER</h3>
                  <br/>
                  <ul>
                    <li>
                      House 1 has a listing price of: ${data[houseList[houseCount]].hdpData.homeInfo.price}
                      <br/>
                      <br/>
                      {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount]].hdpData.homeInfo.zipcode).price}
                      </p>
                      :  
                      <p>
                        Hint not available for this listing.
                      </p>}
                    </li>
                    <li>
                    House 2 has a listing price of: ${data[houseList[houseCount+1]].hdpData.homeInfo.price}
                      <br/>
                    <br/>
                    {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount+1]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount+1]].hdpData.homeInfo.zipcode).price}
                      </p>
                      : 
                      <p>
                        Hint not available for this listing.
                      </p>
                      }
                    </li>
                    <li>
                    House 3 has a listing price of: ${data[houseList[houseCount+2]].hdpData.homeInfo.price}
                      <br/>
                    <br/>
                    {hintFailed === false ? 
                      <p>
                      *The average home price in zipcode {data[houseList[houseCount+2]].hdpData.homeInfo.zipcode} is ${average.find(t=>t.zip === data[houseList[houseCount+2]].hdpData.homeInfo.zipcode).price}
                      </p>
                      :  
                      <p>
                        Hint not available for this listing.
                      </p>}
                    </li>
                  </ul>
                  <br/>

                  <div className="roundScore final">
                    <span>Final Streak:</span>
                    <span>{totalCount}</span>
                  </div>
                </div>

                <div className="modalBtnDiv">
                  <button
                    className="modalBtn"
                    disabled={houseCount > 1449}
                    onClick={nextGame}
                  >
                    Play Again?
                  </button>
                  <button
                    className="modalBtn"
                    disabled={saved}
                    onClick={handleAddMost}
                  >
                    Save Score
                  </button>
                  <button
                    className="modalBtn"
                    onClick={() => setCurrentPage("home")}
                  >
                    Main Menu
                  </button>
                </div>
                <p className="fineText">*according to Zillow ( ZHVI )</p>
              </div>
            </div>
          ) : null}
          <div className="houseSection">
          {makeHouseArr().map((house) => (
            <div className="gameSection moreLessSection" key={house.imgSrc}>
              <div className="infoColumn">
                <button
                  className="moreBtn"
                  onClick={() => checkAnswer(house.hdpData.homeInfo.price)}
                >
                  Most Expensive
                </button>
                <ul className="specOptions">
                  <li>
                    <h4>Location:</h4>
                    <span id="location">
                      {house.hdpData.homeInfo.city},{" "}
                      {house.hdpData.homeInfo.state}
                      {mapOpen ? (
                        <button
                          className="mapBtn"
                          onClick={() => {
                            setMapOpen(false);
                          }}
                        >
                          Return to House
                        </button>
                      ) : (
                        <button
                          className="mapBtn"
                          onClick={() => {
                            setMapOpen(true);
                          }}
                        >
                          View on Maps
                        </button>
                      )}
                    </span>
                  </li>
                  <li>
                    <h4>Zipcode</h4>
                    <span id="zipcode">{house.hdpData.homeInfo.zipcode}</span>
                  </li>
                  <li>
                    <h4>Square Footage:</h4>
                    <span id="squareFoot">
                      {house.hdpData.homeInfo.livingArea}
                    </span>
                  </li>
                  <li>
                    <h4>Bed/Bath Count:</h4>
                    <span id="bedBathCount">
                      {house.hdpData.homeInfo.bedrooms} Bed{" "}
                      {house.hdpData.homeInfo.bathrooms} Bath
                    </span>
                  </li>
                  <li>
                    <h4>Lot Size:</h4>
                    <span id="lotSize">
                      {house.hdpData.homeInfo.lotAreaValue.toFixed(2)}{" "}
                      {house.hdpData.homeInfo.lotAreaUnit}{" "}
                    </span>
                  </li>

                  {showHint ? (
                    <div className="hintEl">
                      <p className="hintText">
                        Average home price for this zipcode is: $
                        {
                          average.find(
                            (t) => t.zip === house.hdpData.homeInfo.zipcode
                          ).price
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="hintEl">
                      {hintFailed === false ? 
                      <button className="mapBtn" onClick={handleUseHint}>
                        Use Hint
                      </button>
                      :
                      <p>
                        Hint not available for this listing.
                      </p>
                      }
                    </div>
                  )}
                </ul>
              </div>
              <div className="listingImages">
                {mapOpen ? (
                  <iframe
                    className="houseListingImage houseMap"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDDriTZDiM_9BYt07b7VQzKKbTVQEIBFOs&zoom=10&q=${house.hdpData.homeInfo.zipcode}`}
                  ></iframe>
                ) : (
                  <img
                    className="houseListingImage"
                    src={house.imgSrc}
                    alt="house listing"
                  ></img>
                )}
              </div>
            </div>
          ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default Most;
