import React, { useState, useEffect } from "react";
import Auth from "../../utils/auth";

const Home = (props) => {
  const { setCurrentPage } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="loaderDiv">
          <span className="loader"></span>
        </div>
      ) : (
        <section className="homeDiv">
          <div className="welcome">
            <h2>Welcome To Appraiser!</h2>
            <p>
              Learn what kind of house money can buy all around America by playing a fun game
            </p>
            <p>
              Using information like zipcode, square footage, and bedroom count appraise
              houses and see how close you are to the actual listing price.
            </p>
            <p>
              Save your scores and see where you place on the Leaderboard and come back often
              as the house list is updated regularly.
            </p>
          </div>
          <div className="homeDivSecondRow">

            <div className="homeDivSecond">
             <ul className="homeDivUl">
              There are currently 3 unique gamemodes:
              <li>Classic Mode- Appraise 5 houses and earn points for accuracy up to a max of 100 points</li>
              <li>Streak Mode- Appraise houses back to back for as long as you stay accurate enough.</li>
              <li>Expensive-ist Mode- You are shown 3 houses and you guess which costs the most, see how many you can get in a row</li>
             </ul>
            </div>

            <div className="homeDivSecond">
              <p>
                There are nearly 2000 houses in the list that you will encounter
                with at least 20 houses from each state.
              </p>
              <p>
                Using Zillow's Home Value Index (ZHVI) you can access average home prices
                for each zipcode to help you learn the house prices around the U.S.
              </p>
              <p>
                
              </p>
            </div>

          </div>

          <div className="secondRowRight">
            <div className="loginPlayDiv">
              {Auth.loggedIn() ? (
                <div
                  className="playGameDiv"
                  onClick={() => {
                    setCurrentPage("game");
                  }}
                >
                  <h3>Click Here To Play Now!</h3>
                </div>
              ) : (
                <div
                  className="loginLinkDiv"
                  onClick={() => setCurrentPage("login")}
                >
                  <h3>Login To Start Playing</h3>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Home;
