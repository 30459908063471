import React, { useState, useEffect } from "react";
import Auth from "../../utils/auth";
import logo from "../../assets/images/logo3.png";

import {
  BsFillArrowRightSquareFill,
  BsHouseFill,
  BsPersonFill,
  BsController,
  BsTrophyFill
} from "react-icons/bs";

const Nav = (props) => {
  const { setCurrentPage } = props;
  const [ isChecked, setIsChecked ] = useState(true);

  useEffect(() => {
    window.addEventListener("click", onOutsideMenuClick);

    return () => {
      window.removeEventListener("click", onOutsideMenuClick);
    }
  }, [])

  const onOutsideMenuClick = (e) => {
    if (e.target.id === 'toggle') {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }

  return (
    <div className="menu">
      <div className="wrapper-fullscreen">
        <div className="sidebar">
          <ul>
            <li onClick={() => setCurrentPage("home")}>
              <a href="#home">
                <span>
                  <BsHouseFill />
                </span>
                <p className="menu-option">Home</p>
              </a>
            </li>
            {Auth.loggedIn() ? (
              <li onClick={() => setCurrentPage("game")}>
                <a href="#game">
                  <span>
                    <BsController />
                  </span>
                  <p className="menu-option">New Game</p>
                </a>
              </li>
            ) : (
              <li onClick={() => setCurrentPage("login")}>
                <a href="#login">
                  <span>
                    <BsController />
                  </span>
                  <p className="menu-option">New Game</p>
                </a>
              </li>
            )}
            <li onClick={() => setCurrentPage("leader")}>
                <a href="#leader">
                  <span>
                    <BsTrophyFill />
                  </span>
                  <p className="menu-option">Leaderboard</p>
                </a>
              </li>
            {Auth.loggedIn() ? (
              <li onClick={() => setCurrentPage("account")}>
                <a href="#account">
                  <span>
                    <BsPersonFill />
                  </span>
                  <p className="menu-option">Account</p>
                </a>
              </li>
            ) : (
              <li onClick={() => setCurrentPage("login")}>
                <a href="#login">
                  <span>
                    <BsPersonFill />
                  </span>
                  <p className="menu-option">Account</p>
                </a>
              </li>
            )}

            {Auth.loggedIn() ? (
              <li className="loginNav" onClick={() => Auth.logout()}>
                <a href="#home">
                  <span>
                    <BsFillArrowRightSquareFill />
                  </span>
                  <p className="menu-option">Logout</p>
                </a>
              </li>
            ) : (
              <li className="loginNav" onClick={() => setCurrentPage("login")}>
                <a href="#login">
                  <span>
                    <BsFillArrowRightSquareFill />
                  </span>
                  <p className="menu-option">Login</p>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="main_content">
          <div className="header">
            <img
              onClick={() => setCurrentPage("home")}
              src={logo}
              width="100%"
              alt="appraiser logo"
            />
          </div>
        </div>
      </div>
      <div className="wrapper-mobile">
        <div className="logoDiv">
          <div className="header">
            <img
              onClick={() => setCurrentPage("home")}
              src={logo}
              width="100%"
              alt="appraiser logo"
            />
          </div>
        </div>
        <nav className="smallScreenMenu">         
          <ul>
            <li onClick={() => {setCurrentPage('home')}}>
              <a href="#home">Home <span><BsHouseFill /></span></a>
            </li>
            {Auth.loggedIn() ? (
            <li onClick={() => {setCurrentPage('game')}}>
              <a href="#game">Game <span><BsController /></span></a>
            </li>
            ):(
            <li onClick={() => {setCurrentPage('login')}}>
              <a href="#login">Game <span><BsController /></span></a>
            </li>
            )}
            <li onClick={() => {setCurrentPage('leader')}}>
              <a href="#leader">Leaderboard <span><BsTrophyFill /></span></a>
            </li>
            {Auth.loggedIn() ? (
            <li onClick={() => {setCurrentPage('account')}}>
              <a href="#account">Account <span><BsPersonFill /></span></a>
            </li>
            ):(
            <li onClick={() => {setCurrentPage('login')}}>
              <a href="#login">Account <span><BsPersonFill /></span></a>
            </li>
            )}
            {Auth.loggedIn() ? (
            <li onClick={() => Auth.logout()}>
              <a href="#home">Logout <span><BsFillArrowRightSquareFill /></span></a>
            </li>
            ):(
            <li onClick={() => {setCurrentPage('login')}}>
              <a href="#login">Login <span><BsFillArrowRightSquareFill /></span></a>
            </li>
            )}
          </ul>
        </nav>
        
      </div>
    </div>
  );
};

export default Nav;
