import React, { useState } from "react";
import { ADD_USER } from "../../utils/mutations";
import Auth from "../../utils/auth";
import { useMutation } from "@apollo/react-hooks";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const Login = (props) => {
  // login form
 
  const [togglePass2, setTogglePass2] = useState('password');

  // const { setCurrentPage } = props;

  const handleSignUpPassToggle = () => {
    if(togglePass2 === "password") {
      setTogglePass2('text')
    } else {
      setTogglePass2('password')
    }
  }

  
  // signup form

  const [signupState, setSignupState] = useState({ email: "", password: "" });
  const [addUser, { error }] = useMutation(ADD_USER);

  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    try {
      const mutationResponse = await addUser({
        variables: {
          username: signupState.username,
          email: signupState.email,
          password: signupState.password,
          firstName: signupState.firstName,
          lastName: signupState.lastName,
        },
      });
      const token = mutationResponse.data.addUser.token;
      Auth.login(token);
    } catch (e) {
      console.log(e);
    }
  };


  const handleSignupChange = (event) => {
    const { name, value } = event.target;
    setSignupState({
      ...signupState,
      [name]: value,
    });
  };

  

  return (
    <div className="signupDiv">   
      <div className="login">
        <h2>Sign Up</h2>
        <form onSubmit={handleSignupSubmit}>
          <div className="form-el">
            <label className="form-label" htmlFor="firstName">
              First Name:
            </label>
            <input
              className="form-input"
              name="firstName"
              type="firstName"
              id="firstName"
              onChange={handleSignupChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="lastName">
              Last Name:
            </label>
            <input
              className="form-input"
              name="lastName"
              type="lastName"
              id="lastName"
              onChange={handleSignupChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="username">
              Username:
            </label>
            <input
              className="form-input"
              name="username"
              type="username"
              id="username"
              onChange={handleSignupChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              className="form-input"
              name="email"
              type="email"
              id="email2"
              onChange={handleSignupChange}
            />
          </div>
          <div className="form-el">
            <label className="form-label" htmlFor="pwd">
              Password:
            </label>
            <div className="pass-input">
            <input
              className="form-input-password"
              name="password"
              type={togglePass2}
              id="pwd2"
              onChange={handleSignupChange}
            />{togglePass2 === 'password' ? (<i onClick={handleSignUpPassToggle} id="passToggle2">< BsEyeSlash/></i>)
            : (<i onClick={handleSignUpPassToggle} id="passToggle2">< BsEye/></i>)}
            </div>
          </div>
          {error ? (
            <div>
              <p className="error-text">
                <span>Password must be 8 character or longer</span>
                <span>Only letters and numbers allowed for username</span>
                <span>Ensure your email is not already in use</span>
                <span>All fields must be filled out</span>
              </p>
            </div>
          ) : null}
          <div className="form-el">
            <button className="loginBtn" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
          } 


export default Login;
